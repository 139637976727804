import React from 'react';
import { motion, cubicBezier } from 'framer-motion';

export const InfoBar = (props: any) => {
  const { text, top } = props;

  return (
    <motion.div
      id={'home'}
      initial={{ y: -200, opacity: 1 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{
        ease: cubicBezier(0.21, 0.91, 0.42, 1.16),
        delay: 0,
        duration: 0.3,
      }}
      className={`flex w-full py-2 md:pt-2 md:pb-3 bg-[#2BA275] items-center justify-center z-30`}
    >
      <div className={`text-white px-4 lg:px-4 font-medium tracking-[.3em] md:tracking-[.6em] uppercase`}>
        <div className={'text-sm md:text-lg'}>
          <span className={'text-xl md:text-3xl'}>M</span>EDICINAE <span className={'text-xl md:text-3xl'}>V</span>
          ERITATIS <span className={'text-xl lg:text-3xl'}>P</span>ERITUS
        </div>
        <div className={'text-xs hidden md:block'}>honesty in medical expertise</div>
      </div>
    </motion.div>
  );
};
