import TabContext from '@mui/lab/TabContext';
import Box from '@mui/material/Box';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import { ButtonMvp } from '../ButtonMvp';
import { TabsGrid } from './TabsGrid';
import React, { useState } from 'react';

export const Tabs = (props: any) => {
  const [tabValue, setTabValue] = useState('1');

  const handleTabChange = (event: any, newValue: string) => {
    setTabValue(newValue);
  };

  const tabStyles = {
    minWidth: '160px',
    fontWeight: 'bold',
    color: 'black',
    fontSize: '16px',
    letterSpacing: '1px',
    '&.Mui-selected': { color: '#2ba275' },
  };

  return (
    <div
      id={'services'}
      className={
        'flex w-full flex-col items-center px-8 lg:px-16 pt-8 md:pt-16 pb-8 md:pb-16 lg:pb-24 bg-gradient-to-t from-gray-100 to-white drop-shadow-2xl'
      }
    >
      <div className={'flex w-full flex-col max-w-[1280px]'}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={handleTabChange}
              aria-label="lab API tabs example"
              centered
              TabIndicatorProps={{
                style: {
                  backgroundColor: '#2BA275',
                  height: '4px',
                },
              }}
            >
              <Tab label="Attorneys" value="1" sx={tabStyles} />
              <Tab label="Medical Experts" value="2" sx={tabStyles} />
            </TabList>
          </Box>
          <TabPanel value="1" sx={{ padding: 0 }}>
            <div className={'flex flex-col items-start w-full mt-4 lg:mt-12 text-left text-xl leading-8'}>
              We connect attorneys with the top-tier medical experts needed to win their cases. With 20 years of
              experience working with law firms of all sizes, we understand what’s important to you and know how to find
              the perfect expert for your specific needs in personal injury, medical malpractice, and more.
            </div>
            <TabsGrid />
            <div
              className={
                'flex items-center flex-col sm:flex-row w-full mt-6 lg:mt-16 bg-cyan-50 rounded-xl p-8 border-[2px] border-cyan-200'
              }
            >
              <div className={'flex text-2xl font-bold text-left sm:pr-8'}>
                Sign-up in seconds to get access to our pool of experts. We’ll reach out to you to schedule a
                introductory session.
              </div>
              <div className={'flex justify-end mt-8 lg:mt-0'}>
                <a href={'https://app.medverper.com/form/begin-firm-registration'}>
                  <ButtonMvp action={'Sign Up'} secondary={true} />
                </a>
              </div>
            </div>
          </TabPanel>
          <TabPanel value="2" sx={{ padding: 0 }}>
            <div className={'flex flex-col items-start w-full mt-4 lg:mt-12 text-left text-xl leading-8'}>
              MVP experts benefit from being able to utilize a single platform to store and review files, automate
              billing, assist in scheduling meetings, and analyze files by our powerful AI software.
            </div>
            <TabsGrid />
            <div
              className={
                'flex items-center flex-col sm:flex-row w-full mt-6 lg:mt-16 bg-cyan-50 rounded-xl p-8 border-[2px] border-cyan-200'
              }
            >
              <div className={'flex w-2/3 text-center text-2xl font-bold sm:pr-8'}>Sign up to be an MVP expert</div>
              <div className={'flex w-1/3 justify-center md:justify-end mt-8 lg:mt-0'}>
                <a href={'https://medverper.com/experts.html'}>
                  <ButtonMvp action={'Sign Up'} secondary={true} />
                </a>
              </div>
            </div>
          </TabPanel>
        </TabContext>
      </div>
    </div>
  );
};
